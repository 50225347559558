<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code >
                <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <!-- <b-col md="4">
                          <b-form-group>
                            <label>Type</label>
                            <v-select v-model="type" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="['Summary','Detailed']"
                            />
                          </b-form-group>
                        </b-col> -->
                         <!-- <b-col md="4">
                          <b-form-group>
                            <label>Branch</label>
                            <v-select v-model="branch" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="['Channel Partner','Franchise','Admin']" @input="getEmployee()"
                            />
                          </b-form-group>
                        </b-col> -->
                        <b-col md="4">
                          <b-form-group>
                            <label>Employee</label>
                            <v-select v-model="employee" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="employeeOptions" />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Select Date</label>
                            <flat-pickr class="form-control"
                              value=""
                              :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range'}"
                              placeholder="Select Date"
                              style="background-color: transparent"
                              @input="getdata($event)"/>
                          </b-form-group>
                        </b-col>


                        </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                </validation-observer>
            </b-card-code>
              <report-table
                :data="data"
                :columns="fields"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportTable from '../../../components/ReportTable.vue'
import axios from '@/components/axios'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Form from '@/components/form/Form.vue'
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
import vSelect from 'vue-select'
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch
  },
  data () {
    return {
      type: '',
      employee: '',
      employeeOptions: [],
      branch:'',
      startdate: '',
      enddate: '',
      result: '',
      data: '',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken:localStorage.getItem('accessToken'),
      data1:[],
      fields: [
        { field: 'id', label: 'S.R. No',   },
        { field: 'startdate', label: 'Start Date' },
        { field: 'enddate', label: 'End Date', },
        { field: 'entrytype', label: 'Type',  },
        { field: 'reason', label: 'Reason',  },
        { field: 'approved', label: 'Approval',  },
        { field: 'total', label: 'Total',  }
      ]
    }
  },
//   filterOptions: {enabled: true, placeholder: 'Search ID'}
// filterOptions: {enabled: true, placeholder: 'Search Start Date
// filterOptions: {enabled: true, placeholder: 'Search End Date
// filterOptions: {enabled: true, placeholder: 'Search Type'}
// filterOptions: {enabled: true, placeholder: 'Search Reason'}
// filterOptions: {enabled: true, placeholder: 'Search Approval'}
// filterOptions: {enabled: true, placeholder: 'Search Total'}
  mounted () {
    this.init()
  },
  methods: {
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData () {
      const data = {
        employee_id:this.employee.id,
        startdate:this.startdate,
        enddate:this.enddate
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/leavereport`
      })
        .then((response) => {
          this.data = response.data.data
        })
    },
    init () {
      this.getEmployee()
    },
    async getEmployee () {
      this.employeeOptions = []
        await axios({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`
          },
          url: `${this.baseApi}/getEmployee`
        })
          .then((response) => {
            this.employeeOptions = response.data.data
            console.log(this.employeeOptions,"employee")
          })
      }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
